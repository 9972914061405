import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import Switch from '@mui/material/Switch';
import ContrastRoundedIcon from '@mui/icons-material/ContrastRounded';
import { Link } from "react-router-dom";
import { useProps } from '../App.js';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';

export default function TemporaryDrawer() {

  const {
    mode,
    setMode,
    isFollowSysTheme,
    setIsFollowSysTheme,
  } = useProps();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleFollow = () => {
    localStorage.setItem('ThemeFollow', !isFollowSysTheme);
    setIsFollowSysTheme(!isFollowSysTheme);
    window.location.reload();
  }

  const handleMode = () => {
    localStorage.setItem('NightMode', !mode);
    setMode(!mode);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>

      {/* Home && Submit */}
      <List>
        {[

          { to: `/`, key: "进度跟踪", secondary: null, disabled: false, icon: <EventRepeatRoundedIcon /> },
          { to: `/resume`, key: "关于丽丽", icon: <PersonSearchRoundedIcon /> },
          { to: `/story`, key: "光辉事迹", icon: <InfoRoundedIcon /> },

        ].map(({ to, key, secondary, disabled, icon }) => (
          <Link key={key} to={to} style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <ListItem disablePadding>
              <ListItemButton disabled={disabled} >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={key} secondary={secondary} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>

      <Divider />

      {/* Theme controller */}
      <List>
        <ListItem key={"Theme"} disablePadding>
          <ListItemButton onClick={handleFollow}>
            <ListItemIcon>
              <ContrastRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={"跟随系统主题"} />
            <Switch checked={isFollowSysTheme} onChange={handleFollow} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Mode"} disablePadding>
          <ListItemButton onClick={handleMode} disabled={isFollowSysTheme}>
            <ListItemIcon>
              {mode ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
            </ListItemIcon>
            <ListItemText primary={mode ? "白天模式" : "夜间模式"} />
          </ListItemButton>
        </ListItem>
      </List>

    </Box>
  );

  return (
    <div>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}