import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ButtonAppBar from './components/Appbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import React, { useState } from 'react';
import { Container, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

const PropsContext = React.createContext(null);

export function useProps() {
  const context = React.useContext(PropsContext);
  if (!context) throw new Error("useProps must be used within a PropsContext");
  return context;
}

function Contents({ mode, setMode, isFollowSysTheme, setIsFollowSysTheme }) {

  const props = {
    mode, setMode,
    isFollowSysTheme, setIsFollowSysTheme,
  }

  return (

    <div className="App">
      <header className="App-header">
        <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />

        <Container disableGutters>

          {/* 顶栏 */}
          <PropsContext.Provider value={props}>
            <ButtonAppBar />
          </PropsContext.Provider>

          <Toolbar />

          <PropsContext.Provider value={props}>
            <Outlet />
          </PropsContext.Provider>

        </Container>

      </header>
    </div>

  );
}


export default function App() {

  const follow = localStorage.getItem('ThemeFollow') === 'false' ? false : true;
  const [isFollowSysTheme, setIsFollowSysTheme] = useState(follow);
  const [mode, setMode] = useState(checkIsDarkMode());

  // 夜间模式
  function checkIsDarkMode() {
    if (isFollowSysTheme) {
      try {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      } catch (err) {
        return false;
      }
    } else {
      return localStorage.getItem('NightMode') === 'true' ? true : false
    }
  }

  // mode对应Override部件样式
  const appTheme = createTheme({
    palette: {
      mode: mode ? 'dark' : 'light',
      background: {
        default: mode ? '#242525' : '#eeeeee',
      }
    },
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            '&.Roadmap': {
              color: mode ? '#c9c2b7' : '#242525',
              background: mode ? '#242525' : '#eeeeee',
            },
            '&.Home': {
              color: mode ? '#c9c2b7' : '#242525',
              background: mode ? '#242525' : '#eeeeee',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            color: mode ? '#c9c2b7' : '#242525',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={appTheme}>

      <CssBaseline />

      <Contents
        mode={mode}
        setMode={setMode}
        isFollowSysTheme={isFollowSysTheme}
        setIsFollowSysTheme={setIsFollowSysTheme}
      />

    </ThemeProvider>
  );
}
