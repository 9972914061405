import * as React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import { Avatar, Box, Button, ImageListItem, Link, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import QQ1 from '../img/QQ1.jpg';
import QQ2 from '../img/QQ2.jpg';
import XY1 from '../img/XY1.jpg';
import XY2 from '../img/XY2.jpg';
import XY3 from '../img/XY3.jpg';
import X1 from '../img/X1.jpg';
import bilibili from '../img/bilibili.jpg';
import bilibili2 from '../img/bilibili2.jpg';
import beipa from '../img/beipa.jpg';
import WX from '../img/WX.jpg';
import ZFB from '../img/ZFB.jpg';

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { gt1 } from './slides'

function LightboxButton({ onClick }) {
    return (
        <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Button variant="contained" onClick={onClick}>
                查看挂条备档
            </Button>
        </Box>
    );
}

export default function ResumeContent() {

    const [gt1pen, setGt1pen] = React.useState(false);

    return (
        <DialogContent className='Roadmap' sx={{ textAlign: 'start' }}>

            <Lightbox
                key={1}
                open={gt1pen}
                close={() => setGt1pen(false)}
                slides={gt1}
                plugins={[Zoom, Counter, Captions]}
            />

            <Typography variant="h4" gutterBottom>
                关于丽丽
            </Typography>

            <Alert severity="warning">
                注意，以下信息仅提供给各位吃瓜人士或者潜在受害者用于交叉对比以便于辨认出换皮丽丽，
                因此将不会出现将丽丽的个人信息全披露的情况，本站上线目的是为了试图尽量挽回损失和找到更多被丽丽欺骗的受害者，
                同时在丽丽还光所有的债务之前实时记录丽丽的光荣事迹，请在<b>合法范围内利用以下信息。</b>
            </Alert>

            <Divider sx={{ margin: 2 }} />

            <Typography variant="h6" gutterBottom>
                # 态度
            </Typography>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ImageListItem sx={{ maxWidth: 1200, alignContent: 'center' }}>
                        <img
                            src={beipa}
                            alt='被扒'
                        />
                    </ImageListItem>
                </ListItem>
            </List>

            <br />

            <Typography variant="body1">
                OS：我的化学老师曾经说过，出来混迟早都是要还的，<b>不做亏心事谁有空扒你个傻逼。</b>
            </Typography>

            <Divider sx={{ margin: 2 }} />

            <Typography variant="h6" gutterBottom>
                # 个人情况简介
            </Typography>

            <Typography variant="body1">
                sadouxi，又称丽丽，或者我们可以叫他王某，吉林长春人，现居吉林长春南*区。
            </Typography>

            <Typography variant="body1">
                新浪微博宝可梦圈知名代购（过去式，账号因为键政已暴毙），明面上是个搞代购的，
                代购内容为海外游戏/cd/杂志等，但代购<b>实际上是为了骗钱填自己的信用卡和各种借贷窟窿</b>，
                <b>代购商品实际根本没下单</b>或开团下单仅下单一少部分然后选择性发货（谁不好惹谁催就给谁发）。
            </Typography>

            <Typography variant="body1">
                今年四月，有人找他要货他给不了货手头又没钱退，于是他去借小额贷，但是被小贷搞了款没下来，
                于是打电话报警，警察调查发现<b>他把卡/支付宝等账户借给灰色产业链跑分洗黑钱</b>（自己说是为了能借到更多的钱），
                于是他这报警成了自爆卡车把自己也送了进去👉微博有人实锤挂他+找他代购的人发现他失联👉东窗事发。

            </Typography>

            <Link href="https://weibo.com/2817485822/Oa7QMeAp0" variant="body1" underline="always" target="_blank">
                点击前往传奇微博挂条 (感谢微博用户Sakoyui)
            </Link>

            <LightboxButton onClick={() => setGt1pen(true)} />

            <Divider sx={{ margin: 2 }} />

            <Typography variant="h6" gutterBottom>
                # 常用网名、id
            </Typography>

            <Typography variant="body1">
                sadouxi、丽丽-风暴烈酒
            </Typography>

            <Divider sx={{ margin: 2 }} />

            <Typography variant="h6" gutterBottom>
                # 各类社交帐号
            </Typography>

            <Typography variant='h6'>QQ</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="大号" src={QQ1} >大号</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="丽丽-风暴烈酒"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    70***1581
                                </Typography>
                                {" —— 由于丽丽洗钱跑分自爆卡车，目前大号登录手机已被JC扣押，大号是无法使用的失联状态"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="小号" src={QQ2} >小号</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="丽丽"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    37****6821
                                </Typography>
                                {" —— 绝赞装死天天在家里痛风玩游戏中，嘻嘻"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>

            <Divider />

            <Typography variant='h6'>闲鱼</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="闲鱼账号1" src={XY1} >闲鱼账号1</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="丽丽-风暴烈酒"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    会员名：s***i
                                </Typography>
                                {" —— 手机被扣押没法用的账号1"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="闲鱼账号2" src={XY2} >闲鱼账号2</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="异度之友"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    会员名：p***6
                                </Typography>
                                {" —— 卖垃圾的账号2。异度之友，但是不玩奶刃"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="闲鱼账号3" src={XY3} >闲鱼账号3</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="明天会更好"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    会员名：x***6
                                </Typography>
                                {" —— 跟QQ小号一样临时用的账号3，信我，你明天绝对不会更好"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>

            <Divider />

            <Typography variant='h6'>X (former Twitter)</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="大号" src={X1} >大号</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="丽丽-风暴烈酒"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    <Link href="https://twitter.com/sadouxi" variant="body1" underline="always" target="_blank">
                                        @sadouxi
                                    </Link>
                                </Typography>
                                {" —— 绝赞装死中，大概也是因为手机被扣押了登不上，乐"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>

            <Divider />

            <Typography variant='h6'>哔哩哔哩</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="大号" src={bilibili} >大号</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="丽丽-风暴烈酒"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    <Link href="https://b23.tv/t187pJR" variant="body1" underline="always" target="_blank">
                                        @sadouxi
                                    </Link>
                                </Typography>
                                {" —— 诶，兄弟你怎么似了？"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <ListItem alignItems="flex-start">
                    <ImageListItem sx={{ maxWidth: 400, alignContent: 'center' }}>
                        <img
                            src={bilibili2}
                            alt='乐'
                        />
                    </ImageListItem>
                </ListItem>
            </List>

            <Divider />

            <Typography variant='h6'>微信</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="丽丽-风暴烈酒" src={WX} >丽丽-风暴烈酒</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="丽丽-风暴烈酒"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    wang*****3587
                                </Typography>
                                {" —— 手机被扣押了登不上"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>

            <Divider />

            <Typography variant='h6'>支付宝</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="特富不再" src={ZFB} >特富不再</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="特富不再"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    70***1581@qq.com
                                </Typography>
                                {" —— QQ同号，手机被扣押了登不上"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>

            <Divider />

            <Typography variant='h6'>各种主机游戏账号（待更新……）</Typography>
            <Typography variant='body1'>突然觉得这个有杀伤力一点，毕竟没钱再remake了，待我收集下各个平台的信息，稍后更上。</Typography>

            <Divider sx={{ margin: 2 }} />

            <Box sx={{ mb: 2, textAlign: 'center' }}>
                <Typography variant="body2">
                    看到这里，相信你已经了解了丽丽的社交活动范围，同时确定了自己有没有跟他互动过、
                    是不是受害者的其中一员。下面，让我们来给你介绍他的光辉诈骗事迹~
                </Typography>
                <Button variant="contained" href='/story'>观赏事迹</Button>
            </Box>

        </DialogContent>
    )
}