import image1 from "./img/ep1/image1.jpg"
import image2 from "./img/ep1/image2.jpg"
import image3 from "./img/ep1/image3.jpg"

import image4 from "./img/ep2/image4.jpg"
import image5 from "./img/ep2/image5.jpg"

import JZ1 from "./img/ep4/JZ1.jpg"
import JZ2 from "./img/ep4/JZ2.jpg"
import JZ3 from "./img/ep4/JZ3.jpg"
import JZ4 from "./img/ep4/JZ4.jpg"
import JZ5 from "./img/ep4/JZ5.jpg"

import TT1 from "./img/ep6/TT1.jpg"

import XB1 from "./img/ep7/XB1.jpg"

import GT0 from "./img/gt1/GT0.jpg"
import GT1 from "./img/gt1/GT1.jpg"
import GT2 from "./img/gt1/GT2.jpg"
import GT3 from "./img/gt1/GT3.jpg"
import GT4 from "./img/gt1/GT4.jpg"
import GT5 from "./img/gt1/GT5.jpg"
import GT6 from "./img/gt1/GT6.jpg"
import GT7 from "./img/gt1/GT7.jpg"


export const gt1 = [
    { src: GT0 },
    { src: GT1 },
    { src: GT2 },
    { src: GT3 },
    { src: GT4 },
    { src: GT5 },
    { src: GT6 },
    { src: GT7, description: "有钱看36次电影没钱下单的丽丽desu~" },
]

export const slides1 = [
    { src: image1, description: "倒霉博主" },
    { src: image2, description: "与冠军的聊天截图，因为原博直接公开了，这里也不打码了。" },
    { src: image3, description: "群内交叉验证结果——惯犯" },
]

export const slides2 = [
    { src: image4, description: "苦主要求丽丽出示照片证明自己确实有买过代购物品，但是丽丽完全没买，开始秀高端操作。" },
    { src: image5, description: "兄弟的透视水平有待提高。" },
]

export const slides4 = [
    { src: JZ1, description: "暂时代理此事的群友登上了丽丽在戒指购买网站的账号" },
    { src: JZ2, description: "发现加入了购物车期待发货" },
    { src: JZ3, description: "丽丽交代自己骗来的钱挪用去填（信用卡/借贷）窟窿" },
    { src: JZ4, description: "拖字诀！" },
    { src: JZ5, description: "拖字诀，再放送！（每个来要债的都是一套说辞，还不还得出来看天命~）" },
]

export const slides6 = [
    { src: TT1, description: "如果你看过那条挂人微博，那你会发现那条微博P8就是他偷图盗图成性的实锤，感谢原博的整理。" },
]

export const slides7 = [
    { src: XB1, description: "（右边是丽丽）但凡你能掏出来一套甚至半套XB3都好呢？（已核对转运仓记录，XB3就是一套都没买过）" },
]