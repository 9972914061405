import * as React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";

import { slides1, slides2, slides4, slides6, slides7 } from './slides'
import { Box, Button } from '@mui/material';

function LightboxButton({ onClick }) {
    return (
        <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Button variant="contained" onClick={onClick}>
                查看相关图片
            </Button>
        </Box>
    );
}

export default function ResumeContent() {

    const [ep1Open, setEp1Open] = React.useState(false);
    const [ep2Open, setEp2Open] = React.useState(false);
    const [ep4Open, setEp4Open] = React.useState(false);
    const [ep6Open, setEp6Open] = React.useState(false);
    const [ep7Open, setEp7Open] = React.useState(false);

    const Lightboxs = [
        {
            ep: <Lightbox
                key={1}
                open={ep1Open}
                close={() => setEp1Open(false)}
                slides={slides1}
                plugins={[Zoom, Counter, Captions]}
            />
        },
        {
            ep: <Lightbox
                key={2}
                open={ep2Open}
                close={() => setEp2Open(false)}
                slides={slides2}
                plugins={[Zoom, Counter, Captions]}
            />
        },
        {
            ep: <Lightbox
                key={4}
                open={ep4Open}
                close={() => setEp4Open(false)}
                slides={slides4}
                plugins={[Zoom, Counter, Captions]}
            />
        },
        {
            ep: <Lightbox
                key={6}
                open={ep6Open}
                close={() => setEp6Open(false)}
                slides={slides6}
                plugins={[Zoom, Counter, Captions]}
            />
        },
        {
            ep: <Lightbox
                key={7}
                open={ep7Open}
                close={() => setEp7Open(false)}
                slides={slides7}
                plugins={[Zoom, Counter, Captions]}
            />
        },
    ]

    return (
        <DialogContent className='Roadmap' sx={{ textAlign: 'start' }}>

            {Lightboxs.map(({ ep }) => (<>{ep}</>))}

            <Typography variant="h4" gutterBottom>
                光辉事迹
            </Typography>

            <Alert severity="info">
                持续施工中，欢迎投稿，可将你所知道的丽丽的恶劣事迹发送到
                <b> admin@sadouxi.icu</b> 。
                注意，投稿时请<b>尽量附上相关图片作为证据</b>，没有证据会给站主在判断事情真伪上增添难度。
                另外，为了保护你的个人隐私，记得给涉及你个人信息的内容打码，丽丽的可以保留，站主收到后会进行适当编辑再放到站上。
            </Alert>

            <br />



            <Typography variant="h6" gutterBottom>
                <b>1. 奥运亚军亚运冠军？我直接骗！</b>
            </Typography>

            <Typography variant="body1" gutterBottom>
                是的他<b>连冠军都骗</b>，前几年宝可梦出过一只等身大的风速狗毛绒，微博上这位倒霉怪给冠军推荐了找丽丽代购，
                然后……当然货是没有的，钱嘛，还得微博这位牵线搭桥的倒霉催朋友威逼才还上了，无息贷款两年，太爽了；
            </Typography>

            <LightboxButton onClick={() => setEp1Open(true)} />

            <Divider sx={{ margin: 2 }} />



            <Typography variant="h6" gutterBottom>
                <b>2. 传奇微博挂条再放送</b>
            </Typography>

            <Typography variant="body1" gutterBottom>
                下面节选经典P图十年练习生，<b>不是拍照验货做不起，而是P图盗图更有性价比。</b>
            </Typography>

            <LightboxButton onClick={() => setEp2Open(true)} />

            <Divider sx={{ margin: 2 }} />



            <Typography variant="h6" gutterBottom>
                <b>3. 代购物品超期滞纳至销毁</b>
            </Typography>

            <Typography variant="body1" gutterBottom>
                2022年起至被揭露代购骗钱期间，<b>有价值5万以上</b>的代购物品因为没钱支付国际运费而超期滞纳在海外港/日/美转运仓库，最后被转运仓直接销毁。
                其中有已经绝版的铁盒收藏版游戏等珍贵物。2022年美仓记录更是<b>除了他自己的大神手办发了回来之外，其余通通被销毁。</b>
                目前，仍有少部分滞纳未销毁的代购物品堆在海外仓，但是要发回来需要交的<b>滞纳金比重新买这东西更贵；</b>
            </Typography>

            <Divider sx={{ margin: 2 }} />



            <Typography variant="h6" gutterBottom>
                <b>4. 代购婚戒，完婚三年戒指无影踪</b>
            </Typography>

            <Typography variant="body1" gutterBottom>
                RT，丽丽接了一对情侣的宝可梦伊布婚戒代购订单。戒指当时<b>完税价值约2万，到完婚了戒指还没影</b>，
                期间丽丽有无数次机会可以补单，但是后来群友在买戒指的网站登上他账号一看——丽丽：<b>已添加购物车，期待发货。</b>
                现在戒指的价格还涨了，损失惨重；同时，这里也牵涉出来丽丽的另外一个套钱手段，请看P4。
                丽丽在根本没有下单商品的情况下，也会以过海关收税、支付国内外运费这两个藉口来收钱~
            </Typography>

            <LightboxButton onClick={() => setEp4Open(true)} />

            <Divider sx={{ margin: 2 }} />



            <Typography variant="h6" gutterBottom>
                <b>5. 千错万错，都是海关的错</b>
            </Typography>

            <Typography variant="body1" gutterBottom>
                在自己的代购群里<b>点草本地海关清关效率极低</b>，包裹迟迟不放行。
                群友后来一查为啥这包裹在海关待满了三个月呢？哦，因为丽丽让<b>包裹在海关放置了足足三个月</b>，
                <b>海关准备退运/销毁了才交钱放行。</b>海关？我的私人仓库罢了；
            </Typography>

            <Divider sx={{ margin: 2 }} />



            <Typography variant="h6" gutterBottom>
                <b>6. 不止P图高手，还是盗图小能手</b>
            </Typography>

            <Typography variant="body1" gutterBottom>
                <b>PS/闲鱼/ebay盗图伪造商品到货照片</b>，每当有人质疑或者催进度的时候的惯用伎俩，
                已经数不出来用偷图骗了多少遍了，这里放其中一次。
            </Typography>

            <LightboxButton onClick={() => setEp6Open(true)} />

            <Divider sx={{ margin: 2 }} />



            <Typography variant="h6" gutterBottom>
                <b>7. 60套ns异度之刃3典藏版和？套塞尔达王国之泪</b>
            </Typography>

            <Typography variant="body1" gutterBottom>
                代购群内<b>开团ns异度之刃3典藏版60套</b>（他自己说的），<b>1 套 都 没 下 单；</b>
                ns塞尔达王国之泪情况类似，具体数量未统计，同样是1套都没下单；
            </Typography>

            <LightboxButton onClick={() => setEp7Open(true)} />

            <Divider sx={{ margin: 2 }} />

            <Typography variant="h6" gutterBottom>
                <b>8. 未完待续……（给我投稿）</b>
            </Typography>

        </DialogContent>
    )
}