import React from 'react';
import avatar from '../avatar.png';
import '../App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ReactTyped } from "react-typed";
import { Button, DialogContent, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function Home() {

    const pay = 257868
    const total = 624772.74
    const unpay = (total - pay)
    const progress = (pay / total) * 100;

    return (

        <>

            <DialogContent className='Home' sx={{
                justifyContent: 'center',
                alignItems: 'center',
            }}>

                <Box>
                    <img src={avatar} className="App-logo" alt="logo" />
                </Box>

                <Box>
                    <Typography variant="h2">
                        说给sadouxi
                    </Typography>

                    <Typography variant="h5">
                        <ReactTyped
                            strings={[
                                "朋友侠？",
                                "家人侠？",
                                "P图高手？",
                                "没错，但是你的东西还在清关",
                                "我有我的生活",
                                "早就说了我周末要玩游戏了，耽误我发货了吗？",
                                "你要是觉得我不行就直说，老子现在就给你退，废话不要太多，妈的",
                            ]}
                            typeSpeed={40}
                            backSpeed={50}
                            loop
                        />
                    </Typography>
                </Box>

                <Divider sx={{ margin: 2 }} />

                <Box>
                    <Typography variant="body1">
                        {`涉嫌代购诈骗金额清还情况：`}
                    </Typography>

                    <Typography variant="body2">
                        {`抵押物：190422￥，PC游戏：13964￥，没人要的垃圾：28482￥，奥德赛主机：5000￥，戒指：20000￥`}
                    </Typography>

                    <LinearProgressWithLabel value={progress} />

                    <Typography variant="body1">
                        {`${pay} / ${total} ￥`}
                    </Typography>

                    <Typography variant="body1" color='red'>
                        <b>{`至少仍需清还: ${unpay}￥`}</b>
                    </Typography>
                </Box>

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ mb: 2, textAlign: 'center' }}>
                    <Typography variant="body2">
                        欢迎来到说给sadouxi，点击下方按钮进入主页，我们将带你逐步了解，著名宝圈代购sadouxi，又称丽丽-风暴烈酒，为人不知的传奇老赖故事~
                    </Typography>
                    <Button variant="contained" href='/resume'>深入了解</Button>
                </Box>

            </DialogContent>

        </>

    )
}